
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Table, TableRowsSelect } from "@/utils/decorator";
import { emptyToLine, splitThousands } from "@/utils/common";
import CouponContent from "@/views/marketing/components/coupon-discount-content.vue";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
const marketing = namespace("marketing");
const base = namespace("base");
const coupon = namespace("coupon");
@Component({
  components: {
    CouponContent
  }
})
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("setting.choose-payment-method"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
export default class DiscountCoupon extends Vue {
  @marketing.Action queryCardTemplateList;
  @base.Action getDictionaryList;
  @coupon.Action queryList;
  @coupon.Action disable;
  dayjs = dayjs;
  activityStatusList: any = [];
  couponTypeList: any = [];
  hoverRow = undefined;
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  searchForm = {
    activityName: "",
    activityStatus: "",
    couponType: ""
  };
  cardTemplateList: any[] = [];
  //计算属性
  get searchList() {
    return [
      {
        label: "marketing.discount-coupon-name",
        type: "input",
        value: "",
        prop: "activityName"
      },
      {
        label: "marketing.coupon-type",
        type: "select",
        value: "",
        selectList: this.couponTypeList,
        prop: "couponType"
      },
      {
        label: "finance.state",
        type: "select",
        value: "",
        selectList: this.activityStatusList,
        prop: "activityStatus"
      }
    ];
  }
  created() {
    this.init();
  }
  getVisible() {
    return true;
  }
  mounted() {}
  async init() {
    this.loadListData();
    this.getDictionaryList("coupon_type").then(data => {
      this.couponTypeList = data;
    });
    this.getDictionaryList("coupon_activity_status").then(data => {
      this.activityStatusList = data;
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  copyRow = undefined;
  handleComfirmCommand(command) {
    switch (command) {
      case "edit":
        this.$router.push(`/marketing/created-coupon/${this.hoverRow.activityCode}`);
        break;
      case "copy":
        this.$router.push({
          name: "marketingCreatedCouponAdd",
          params: {
            copyRow: this.hoverRow.activityCode
          }
        });
        break;
      default:
        this.confirmUpdateStatus();
      // 完成入库
    }
  }
  onSureDisable(row) {
    this.disable({ activityCode: row.activityCode }).then(res => {
      if (!res.data) {
        this.$message.success(t("setting.disable-success"));
        this.loadListData();
      }
    });
  }
  confirmUpdateStatus() {
    console.log("gg");
  }
  handleSetCurrentRow(visible, row) {
    this.hoverRow = row;
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.cardTemplateList = data.data.list;
      return data;
    });
  }
  handleView(row) {
    this.$router.push(`/marketing/look-coupon/${row.activityCode}`);
  }
  createdCoupon() {
    this.$router.push({
      name: "marketingCreatedCouponAdd"
    });
  }
  // ////
}
